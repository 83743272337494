import { useStaticQuery, graphql } from 'gatsby';

function useResponsiveImage(image) {

  const { listImages } = useStaticQuery(query);


  const fileName = image ? image.split('/').pop() : false;
  const allImages = listImages.edges.filter(img => img.node.childImageSharp != null );  
  const imageResponsive = allImages.find(element => element.node.childImageSharp.fluid.src.includes(fileName));

  return imageResponsive.node.childImageSharp.fluid;


}

export default useResponsiveImage;

const query = graphql`
  query useResponsiveImage {

    listImages: allFile {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 400, quality: 64) {
              src
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }  
    
  }
`;
